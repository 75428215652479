import constants from "./homeConstants";
import { call } from "@/service";

export default {
  namespaced: true,
  state: {
    jobs: [],
    noOpenings: false,
    allJobs: [],
    singleJob: {},
    categories: [],
    applications: [],
    singleApplication: {},
    jobOffers: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    homeGetters: (state) => (val) => state[val],
  },
  actions: {
    getJobs: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });

      call("get", constants.jobs)
        .then((res) => {
          if (!res.data.data.length) {
            commit("MUTATE", { state: "noOpenings", value: true });
          }
          commit("MUTATE", { state: "jobs", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getAllJobs: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });

      call("get", constants.allJobs)
        .then((res) => {
          commit("MUTATE", { state: "allJobs", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getCategories: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });

      call("get", constants.categories)
        .then((res) => {
          // commit("SET_CATEGORIES", res.data.data);
          commit("MUTATE", { state: "categories", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getSingleJob({ commit }, payload) {
      commit("SET_LOADING", true, { root: true });
      console.log(payload);
      call("get", constants.SINGLEJOB(payload))
        .then((res) => {
          /**check for job expiration or close state */
          if (
            typeof res.data.data[0] === "object" &&
            Object.keys(res.data.data[0]).length > 0
          ) {
            commit("MUTATE", { state: "singleJob", value: res.data.data[0] });
          } else {
            Event.$emit(
              "ApiError",
              "The Job Vacancy was Invalid or has already been Closed\n hence the redirecting to home ..."
            );
            //redirect to home
            Event.$emit("show-closed-alert");
          }

          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    saveApplication: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.submit, data)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Success Submitted");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getApplications({ commit }, payload) {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.APPLICATIONS(payload))
        .then((res) => {
          commit("MUTATE", { state: "applications", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    saveQuestions: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.questions, data)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });

          Event.$emit("ApiError", err.response.data.message);
        });
    },
    submitApplication: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.submit, data)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("redirectToAllapplications");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    createApplication: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.submit, data)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("redirectToAllapplications");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getSingleApplication({ commit }, payload) {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.SINGLEAPPLICATION, payload)
        .then((res) => {
          commit("MUTATE", {
            state: "singleApplication",
            value: res.data.data[0],
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getJobOffers({ commit }, payload) {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.getJobOffer(payload))
        .then((res) => {
          commit("MUTATE", {
            state: "jobOffers",
            value: res.data.data.shift(),
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    patchJobOffer({ commit }, payload) {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.jobOffer, payload)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("back");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
